jQuery(document).ready(function ($) {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('.bs-select').selectpicker({
		noneSelectedText: '(válasszon)',
		liveSearch: false,
	});

	$('a.level').each(function (i, o) {
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai' + 'lt' + 'o:' + c).html(c);
	});
	if ($(window).scrollTop() >= $('.header-fooldal').height()) {
		$('#fooldal-fix-menu').css('visibility', 'visible');
	}

	$(window).on('scroll', function () {
		var scrollTop = $('.header-fooldal').height();
		if ($(window).scrollTop() >= scrollTop) {
			$('#fooldal-fix-menu').css('visibility', 'visible');
		} else {
			$('#fooldal-fix-menu').css('visibility', 'hidden');
		}
	});

	if ($(window).scrollTop() >= 150) {
		$('.body-aloldal .header-aloldal-collapse').addClass('szeles');
	}

	$(window).on('scroll', function () {
		if ($(window).scrollTop() >= 150) {
			$('.body-aloldal .header-aloldal-collapse').addClass('szeles');
		} else {
			$('.body-aloldal .header-aloldal-collapse').removeClass('szeles');
		}
	});

	var $odometer = $('.odometer');
	if($odometer.length) {
		var $window = $(window),
			topPos = $odometer.offset().top - Math.floor($window.height() * 0.9),
			odometer_porgetes = function () {
				if ($(window).scrollTop() >= topPos) {
					$odometer.html($odometer.data('value'));
				}
			};

		odometer_porgetes();
		$(window).on('scroll', odometer_porgetes);
	}

	if ($(".header-fooldal-slider").length) {
		var fooldalSlideBg = function () {
			var W = $(document).width();
			var bg;

			if (W < 768) bg = "bg-xs";
			else if (W < 992) bg = "bg-sm";
			else bg = "bg-md";

			$(".header-fooldal-slide").each(function () {
				$(this).css("background-image", $(this).data(bg));
			});
		};

		fooldalSlideBg();
		$(window).on("resize", fooldalSlideBg);
	}

	$(".header-aloldal-menu-toggle:visible").on("click", function (e) {
		e.preventDefault();

		var $header_fomenu = $(".header-aloldal-fomenu");

		if ($header_fomenu.hasClass("open")) {
			$header_fomenu.removeClass("open");
			$(this).attr("aria-expanded", "false");
		} else {
			$header_fomenu.addClass("open");
			$(this).attr("aria-expanded", "true");
		}
	});

	$(document).on("click", ".almenu-dropdown", function(e){
		e.stopPropagation();

		var $this = $(this);

		$this.siblings(".header-fooldal-alalmenu").slideUp(300, function(){
			$this.next(".header-fooldal-alalmenu").slideDown(300);
		});

	});

	function init_maxlength($selector) {
		$selector = $selector || $(document);
		$selector.find('.maxlength').each(function () {
			var $this = $(this);
			$this.maxlength($.extend({
				max: $this.data('maxlength') || $this.attr('maxlength'),
				feedbackText: '{r} / {m}',
				overflowText: "{r} / {m}",
				truncate: false,
				/*onFull: function (overflow) {
				 if (!overflow) { // Notify via an alert
				 // $this.addClass('')
				 }
				 else { // Flash styling
				 var self = $(this);
				 self.removeClass('maxlength-overflow');
				 setTimeout(function () {
				 self.addClass('maxlength-overflow');
				 }, 200);
				 }
				 }*/
			}, $this.data()));
		});
	}

	init_maxlength();

	// ----- carousel -----
	if ($('.carousel-indicators li.active').length == 0) {
		$('.carousel-indicators li:first').addClass('active');
	}
	if ($('.carousel-inner .item.active').length == 0) {
		$('.carousel-inner .item:first').addClass('active');
	}

	$('.dropdown .active').parents('.dropdown').addClass('active');

	$(".header-fooldal-slider").owlCarousel({
		animateIn: "fadeIn",
		animateOut: "fadeOut",
		autoplay: true,
		autoplayTimeout: 8000,
		autoplayHoverPause: false,
		dots: false,
		items: 1,
		loop: true
	});

	$(".csatolt-galeria").owlCarousel({
		nav: true,
		navText: [
			'<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>',
			'<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>',
		],
		responsiveClass:true,
		/*responsive: {
			0: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1400: {
				items: 5
			}
		},*/
		autoWidth: true,
		stagePadding: 0
	});

	/*$('.map').each(function () {

	 var $this = $(this),
	 loc = new google.maps.LatLng(46.213992, 18.162026),
	 map = new google.maps.Map(this, {
	 zoom: 8,
	 scrollwheel: false,
	 center: loc,
	 mapTypeId: google.maps.MapTypeId.ROADMAP
	 }),
	 marker = new google.maps.Marker({
	 position: loc,
	 map: map,
	 });
	 });*/

	$(".fooldal-rendezveny-naptar").datetimepicker({
		language: $("html").attr("lang"),
		weekStart: 1,
		todayHighlight: 0,
		startView: 2,
		minView: 2,
		maxView: 2,
		format: 'yyyy-mm-dd hh:ii',
		onRender: function (date) {
			var m = String(date.getMonth() + 1);
			var d = String(date.getDate());
			var date_ymd = date.getFullYear() + '-' + (m.length == 1 ? "0" : "") + m + '-' + (d.length == 1 ? "0" : "") + d;
			if (typeof rendezvenyek == "object" && rendezvenyek.hasOwnProperty(date_ymd)) {
				$(this).addClass('bekasegge');
				return ' highlight';
			}
			return '';
		}
	});

	$(".datetimepicker-inline").on("click", ".highlight", function () {
		document.location.replace(rendezvenyek[$(this).data('date')]);
	});

	$('.map').each(function () {

		if($('html').hasClass('akadalymentes')){
			var styles = [
				{
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#212121"
						}
					]
				},
				{
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#212121"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "administrative.country",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#9e9e9e"
						}
					]
				},
				{
					"featureType": "administrative.land_parcel",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative.locality",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#bdbdbd"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#181818"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"featureType": "poi.park",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#1b1b1b"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#2c2c2c"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#8a8a8a"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#373737"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#3c3c3c"
						}
					]
				},
				{
					"featureType": "road.highway.controlled_access",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#4e4e4e"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#616161"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#757575"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#000000"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#3d3d3d"
						}
					]
				}
			];
		} else {
			var styles = [
				{
					"featureType": "all",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "all",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#444444"
						}
					]
				},
				{
					"featureType": "administrative.country",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "off"
						},
						{
							"color": "#835555"
						}
					]
				},
				{
					"featureType": "administrative.country",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"weight": "0.01"
						},
						{
							"hue": "#63ff00"
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative.province",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative.province",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative.locality",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative.land_parcel",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"color": "#f2f2f2"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#71c00c"
						},
						{
							"weight": "0.62"
						},
						{
							"lightness": "53"
						},
						{
							"saturation": "-46"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#c5ccc2"
						}
					]
				},
				{
					"featureType": "landscape.natural",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#078524"
						},
						{
							"lightness": "70"
						},
						{
							"saturation": "-30"
						}
					]
				},
				{
					"featureType": "landscape.natural.landcover",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "off"
						},
						{
							"color": "#22e808"
						}
					]
				},
				{
					"featureType": "landscape.natural.landcover",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#13a388"
						}
					]
				},
				{
					"featureType": "landscape.natural.terrain",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape.natural.terrain",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#07bf2a"
						}
					]
				},
				{
					"featureType": "landscape.natural.terrain",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#15a203"
						}
					]
				},
				{
					"featureType": "landscape.natural.terrain",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#d52121"
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.attraction",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#bf4b4b"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						},
						{
							"color": "#f88317"
						},
						{
							"weight": "0.01"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"color": "#f69f25"
						},
						{
							"weight": "3.57"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "on"
						},
						{
							"weight": "0.1"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#9c19e9"
						},
						{
							"visibility": "on"
						},
						{
							"saturation": "70"
						},
						{
							"lightness": "-15"
						},
						{
							"weight": "1.20"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "simplified"
						},
						{
							"color": "#f29e16"
						},
						{
							"weight": "0.5"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#f89f22"
						},
						{
							"weight": "0.70"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "geometry",
					"stylers": [
						{
							"color": "#fca731"
						},
						{
							"weight": "0.90"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit.line",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit.station.rail",
					"elementType": "geometry",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"color": "#469bec"
						},
						{
							"visibility": "on"
						},
						{
							"weight": "3.85"
						}
					]
				}
			];
		}
		var styledMap = new google.maps.StyledMapType(styles,
			{name: "Baranya Megyei Fejlesztési Ügynükség"});

		var $this = $(this),
			zoom = $(this).data('zoom'),
			markers = $this.data('markers') || [];

		if (!markers.length) return false;

		var bounds = new google.maps.LatLngBounds(),
			// loc = new google.maps.LatLng(46.213992, 18.162026),
			map = new google.maps.Map(this, {
				center: markers[0].position,
				zoom: zoom,
				scrollwheel: false,
			});

		map.mapTypes.set('map_style', styledMap);
		map.setMapTypeId('map_style');

		var infoWindows = [];

		$.each(markers, function (i, e) {
			if (e.content != null) {
				var infowindow = new google.maps.InfoWindow({
					content: e.content
				});
			}
			infoWindows.push(infowindow);
			var marker = new google.maps.Marker({
				map: map,
				position: e.position,
				title: e.title,
				icon: e.icon,
			});

			marker.addListener('click', function () {
				/*if (infowindow) {
					infowindow.close();
				}*/
				for (var i = 0; i < infoWindows.length; i++) {
					infoWindows[i].close();
				}

				infowindow.open(map, marker);
			});

			google.maps.event.addListener(map, 'click', function() {
				infowindow.close();
			});

			bounds.extend(e.position);
			map.fitBounds(bounds);
		});


		var boundsListener = google.maps.event.addListener(map, 'bounds_changed', function (event) {
			this.setZoom(zoom);
			google.maps.event.removeListener(boundsListener);
		});

	});

	$('#telephely').on('change', function () {
		koordinatak($(this).attr('id'));
	});

	$('#szekhely').on('change', function () {
		koordinatak($(this).attr('id'));
	});

	$('select[id="fejlesztes_hely"]').on('change', function () {
		$('select[name="fejlesztes_jaras_select"]').val($(this).val());
		
		var jaras = $('select[name="fejlesztes_jaras_select"] option:selected').text();
		$('input[name="fejlesztes_jaras"]').val(jaras);
	});

	$("#currency_input").on("keyup", function(event ) {                   
		// When user select text in the document, also abort.
		var selection = window.getSelection().toString(); 
		if (selection !== '') {
			return; 
		}       
		// When the arrow keys are pressed, abort.
		if ($.inArray(event.keyCode, [38, 40, 37, 39]) !== -1) {
			return; 
		}       
		var $this = $(this);            
		// Get the value.
		var input = $this.val();            
		input = input.replace(/[\D\s\._\-]+/g, ""); 
		input = input?parseInt(input, 10):0; 
		$this.val(function () {
			return (input === 0)?"":input.toLocaleString("en-US"); 
		}); 
	});
});


/*function submitFormHelyitermelo(token) {
	document.getElementById('helyitermeloForm').submit();
}

function submitFormTag(token) {
	document.getElementById('TagForm').submit();
}*/

function submitFormKapcsolat(token) {
	document.getElementById('kapcsolatForm').submit();
}

function onSubmitHirlevel(token) {
	var form = $("#hirlevelForm");
	$(form).ajaxSubmit({
		method: 'POST',
		success: function (resp) {
			cbs.callbackHandler(form.data('callback'), resp, form);
		}
	});
}

// ----- ajax-os delegálások -----
$(document).on('submit', 'form.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$(this).ajaxSubmit({
		method: $this.data('method') || $this.attr('method') || 'POST',
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

$(document).on('change blur', 'form.auto :input', function (e) {
	e.preventDefault();
	$(this).parents('form').submit();
});

$(document).on('click', 'a.ajax', function (e) {
	e.preventDefault();
	var $this = $(this);
	$.ajax({
		url: $this.attr('href'),
		method: $this.data('method') || 'GET',
		data: $this.data(),
		success: function (resp) {
			cbs.callbackHandler($this.data('callback'), resp, $this);
		}
	});
});

var cbs = {
	callbackHandler: function (cb, resp, $elem) {
		$.each(cb.split(',') || [], function (i, c) {
			if (c && $.isFunction(cbs[c])) {
				cbs[c](resp, $elem);
			}
		});
	},
	hirlevel: function (resp, $elem) {
		$elem.find('.has-error').each(function () {
			$(this).removeClass('has-error').find('span.help-block').remove();
		});

		if (resp.status == 'success') {
			$elem.html(resp.uzenet);
		} else if (resp.status == 'error') {
			$.each(resp.errors, function (key, value) {
				value = $.isArray(value) ? value : [value];
				$elem.find('[name="' + key + '"]').parents('.form-group').addClass('has-error').append('<span class="help-block">' + value.join('<br>') + '</span>');
			});
		}
	}
};

//-------- recaptcha-------------
var recaptcha = function () {
	$('.g-recaptcha').each(function () {
		var $this = $(this);
		grecaptcha.render($this[0], $this.data());
	})
};

//----------------------koordináta beíárs------------
function koordinatak(id) {

	var address = $('#' + id).val();

	var geocoder = new google.maps.Geocoder();
	geocoder.geocode({'address': address}, function (results, status) {
		if (status === google.maps.GeocoderStatus.OK) {
			$('#lat').val(results[0].geometry.location.lat);
			$('#long').val(results[0].geometry.location.lng);
		} else {
			console.log('Geocode was not successful for the following reason: ' + status);
		}
	});

}